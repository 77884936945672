import { log } from "../../helpers/index";

class WaModeController {
  #logPrefix = "WAModeController: ";

  init(updatePlayerState, getRouteState) {
    this.updatePlayerState = updatePlayerState;
    this.getRouteState = getRouteState;

    this.handleZhCourses();

    try {
      this.handleWaResize(window.parent);
      this.handleWaFirstScroll(window.parent);
    } catch {
      console.warn(
        "Unable to initialize first scroll hack and wa resize listener due to CORS."
      );
      this.handleWaResize(window);
      this.handleWaFirstScroll(window);
    }
    this.waMessage();
  }

  handleZhCourses() {
    const isCnUser = localStorage.getItem("langName") === "zh";
    this.updatePlayerState("isCnUser", isCnUser);
  }

  handleWaResize(target) {
    let currentWidth = null;
    target.addEventListener("resize", (e) => {
      if (currentWidth !== e.target.innerWidth) {
        currentWidth = e.target.innerWidth;
        this.waMessage();
      }
    });
  }

  handleWaFirstScroll(target) {
    const firstScrollEvent = () => {
      this.waMessage();
      target.removeEventListener("scroll", firstScrollEvent);
    };
    target.addEventListener("scroll", firstScrollEvent);
  }

  blockPaidContent() {
    this.waMessage(false, "openPaywall");
  }

  unblockPaidContent(url) {
    this.waMessage(url);
  }

  waMessage(url, event) {
    const data = {
      page:
        this.getRouteState?.slug ||
        new URL(window.location.href).searchParams.get("r"),
      height: document.body.offsetHeight,
      url: url ? url : null,
      event: event ? event : null,
    };

    log(data, `${this.#logPrefix}Log`);
    window.parent.postMessage(data, "*");
  }
}

export default WaModeController;
