import React from "react";
import PropTypes from "prop-types";

const ScrollLink = ({
  refParameter,
  limitingParameter,
  classes,
  secondOnClick,
  children,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    !!limitingParameter && secondOnClick();
    !!refParameter &&
      !!refParameter.current &&
      refParameter.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  return (
    <a href="" className={classes || ""} onClick={handleOnClick}>
      {children}
    </a>
  );
};
ScrollLink.propTypes = {
  classes: PropTypes.string,
  secondOnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default ScrollLink;
