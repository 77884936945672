import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SeeDetailsBonus from "./SeeDetailsItemBonus";
import "./index.scss";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const options = {
  renderText: (text) =>
    text
      .split("\n")
      .flatMap((text, i) => [
        i > 0 && <br key={i} />,
        <Fragment key={i + "a"}>{text}</Fragment>,
      ]),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <div className="SeeDetailsOffer__description">{children}</div>
    ),
  },
};

const SeeDetailsOffer = ({
  offerDescription,
  ctaTitle,
  ctaTextLink,
  ctaPathLink,
  tools,
  bonuses,
  offerRef,
  isWaMode,
  spacingBetweenBonuses,
  spacingBetweenTools,
}) => {
  const classes = ["SeeDetailsOffer", !!isWaMode && "SeeDetailsOffer__WaMode"]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={classes} ref={offerRef}>
      <div className="SeeDetailsOffer__title">Special Offer</div>
      <div className="SeeDetailsOffer-plan">
        <div className="SeeDetailsOffer__wrapDescription">
          {renderRichText(offerDescription, options)}
        </div>
        <div className="SeeDetailsOffer-plan__plus" />
        <SeeDetailsBonus
          list={bonuses}
          isLongInterval={spacingBetweenBonuses}
        />
        <SeeDetailsBonus
          isSecond
          list={tools}
          isLongInterval={spacingBetweenTools}
        />
      </div>
      <div className="SeeDetailsOffer-cta">
        <div className="SeeDetailsOffer-cta__title">{ctaTitle}</div>
        <a
          href={ctaPathLink}
          target="_blank"
          className="SeeDetailsOffer__greenBtn"
        >
          {ctaTextLink}
        </a>
      </div>
    </div>
  );
};

SeeDetailsOffer.prototype = {
  offerDescription: PropTypes.object.isRequired,
  bonuses: PropTypes.object.isRequired,
  tools: PropTypes.object.isRequired,
  ctaTextLink: PropTypes.string.isRequired,
  ctaPathLink: PropTypes.string.isRequired,
  ctaTitle: PropTypes.string.isRequired,
  offerRef: PropTypes.object.isRequired,
  isWaMode: PropTypes.bool.isRequired,
  spacingBetweenBonuses: PropTypes.bool.isRequired,
  spacingBetweenTools: PropTypes.bool.isRequired,
};

export default SeeDetailsOffer;
