import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { Link } from "gatsby";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const options = {
  renderText: (text) =>
    text
      .split("\n")
      .flatMap((text, i) => [
        i > 0 && <br key={i} />,
        <Fragment key={i + "a"}>{text}</Fragment>,
      ]),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="UniversityName__description">{children}</p>
    ),
  },
};

const UniversityName = ({
  title,
  description,
  pathLink,
  h1,
  isWaMode,
  handleTitleClick,
}) => {
  return (
    <div className="UniversityName">
      {h1 ? (
        <h1 className="UniversityName-title UniversityName-title__h1">{h1}</h1>
      ) : isWaMode ? (
        <p onClick={handleTitleClick} className="UniversityName-title">
          {title}
        </p>
      ) : (
        <Link to={pathLink} className="UniversityName-title">
          {title}
        </Link>
      )}
      {renderRichText(description, options)}
    </div>
  );
};

UniversityName.prototype = {
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  pathLink: PropTypes.string,
  h1: PropTypes.string,
  isWaMode: PropTypes.bool.isRequired,
  handleTitleClick: PropTypes.func,
};

export default UniversityName;
