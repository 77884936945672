import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import Container from "../../Container";
import { Link } from "gatsby";
import "./index.scss";

const Navigation = (props) => {
  const {
    subLink,
    isWaMode,
    mainTitle,
    items,
    currentPage,
    isBlueView,
    handleRouteChange,
  } = props;

  const classes = getTrueClasses(
    "Navigation",
    isBlueView ? "Navigation__blueView" : "Navigation__oldView",
    isWaMode && "Navigation__waMode"
  );

  const classesLink = (n) =>
    getTrueClasses(
      "Navigation-control__link",
      n.page.slug === currentPage &&
        !subLink &&
        "Navigation-control__link_active"
    );
  const innerLink = (n) =>
    n.subTitle ? (
      <div className="Navigation-control-subInfo">
        {n.isNew ? (
          <span className="Navigation-control-subInfo__badge">NEW</span>
        ) : null}
        <span className="Navigation-control-subInfo__title">{n.subTitle}</span>
      </div>
    ) : null;

  return (
    <section className={classes}>
      <Container webSize>
        <p className="Navigation__title">{mainTitle}</p>
        {!isWaMode && (
          <div className="Navigation-control">
            {items.map((n, i) => {
              if (!handleRouteChange) {
                return (
                  <Link
                    key={i}
                    to={`/${n.page.slug}`}
                    className={classesLink(n)}
                  >
                    {innerLink(n)}
                    {n.title}
                  </Link>
                );
              } else {
                return (
                  <button
                    key={i}
                    className={classesLink(n)}
                    onClick={() => handleRouteChange(n.page.slug)}
                  >
                    {innerLink(n)}
                    {n.title}
                  </button>
                );
              }
            })}
            {subLink && (
              <button className="Navigation-control__link Navigation-control__link_active Navigation-control__link_bread">
                <span>{subLink}</span>
              </button>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

Navigation.prototype = {
  mainTitle: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  pathLink: PropTypes.string,
  subLink: PropTypes.string,
  isWaMode: PropTypes.bool,
  handleRouteChange: PropTypes.func,
};

export default Navigation;
