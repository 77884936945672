import React, { useEffect } from "react";
import Container from "../../common/Container";
import "./index.scss";
import SeeDetailsItem from "./SeeDetailsItem";
import SVGBackTriangle from "../../../assets/images/vector/components/webinars/back-triangle.svg";
import PropTypes from "prop-types";

const SeeDetails = ({
  updatePlayerState,
  data,
  h1,
  offerRef,
  isWaMode,
  heightPage,
  onMount,
  handleRouteChange,
}) => {
  useEffect(() => {
    onMount && onMount();
  }, []);

  return (
    <section className="SeeDetails">
      <Container webSize>
        {isWaMode && (
          <button
            className="SeeDetails__back btn_clear"
            onClick={() => handleRouteChange("university")}
          >
            <img src={SVGBackTriangle} alt="back logo" />
            Back to University
          </button>
        )}
        <SeeDetailsItem
          info={data}
          updatePlayerState={updatePlayerState}
          h1={h1}
          offerRef={offerRef}
          isWaMode={isWaMode}
          heightPage={heightPage}
        />
      </Container>
    </section>
  );
};
SeeDetails.prototype = {
  data: PropTypes.object.isRequired,
  offerRef: PropTypes.object.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  h1: PropTypes.string.isRequired,
  isWaMode: PropTypes.bool,
  heightPage: PropTypes.number,
  onMount: PropTypes.func,
  handleRouteChange: PropTypes.func,
};

export default SeeDetails;
